<template>
  <div class="installment-order">
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <!-- MAIN CONTAINER -->
    <b-row>
      <b-col md="12" lg="12" sm="12">
        <KTCodePreview v-bind:title="' '">
          <template v-slot:toolbar>
            <div class="row">
              <div>
                <b-dropdown size="sm" id="dropdown-1" right>
                  <template slot="button-content">
                    <i style="font-size: 1rem" class="fas fa-cog"></i>
                    <span class="font-weight-bolder">Thao tác</span>
                  </template>
                  <b-dropdown-item @click="excelModal">
                    <span>
                      <i style="font-size: 1rem" class="far fa-file-excel"></i>
                      &nbsp; Xuất Excel</span
                    >
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </template>
          <!-- CONTENT -->
          <template v-slot:preview>
            <!-- FILTER CONTENT -->
            <b-row class="mb-5">
              <b-col>
                <b-form>
                  <vue-autosuggest
                    v-model="searchStock"
                    :suggestions="filteredOptions"
                    @selected="onSelectedStock"
                    :limit="10"
                    @input="onInputChangeStore"
                    :input-props="inputPropStore"
                  >
                    <div
                      slot-scope="{ suggestion }"
                      style="display: flex; align-items: center"
                    >
                      {{ suggestion.item.name }}
                    </div></vue-autosuggest
                  >
                </b-form>
              </b-col>
              <b-col>
                <b-input-group>
                  <div class="date-style">
                    <b-input-group>
                      <date-picker
                        placeholder="Từ"
                        class="form-control form-control-sm"
                        :config="dpConfigs.date"
                        v-model="apiParams.fromDate"
                      ></date-picker>
                    </b-input-group>
                    <span class="ml-1 mr-1"></span>
                    <b-input-group>
                      <date-picker
                        placeholder="Đến"
                        class="form-control form-control-sm"
                        :config="dpConfigs.date"
                        v-model="apiParams.toDate"
                      ></date-picker>
                    </b-input-group>
                  </div>
                </b-input-group>
              </b-col>
              <b-col>
                <b-form>
                  <multiselect
                    v-model="apiParams.contactId"
                    :options="filteredInstallment"
                    :multiple="true"
                    track-by="value"
                    :close-on-select="false"
                    @select="onSelected($event, 'Contact', true)"
                    @remove="onSelected($event, 'Contact', false)"
                    @search-change="onInputed($event, 'Contact')"
                    :show-labels="false"
                    :showNoResults="true"
                    :showPointer="false"
                    placeholder="Chọn dịch vụ trả góp"
                    :block-keys="['Tab', 'Enter']"
                    :internal-search="false"
                    :searchable="true"
                    :preselect-first="false"
                  >
                    <template slot="tag">{{ '' }}</template>
                    <template slot="selection" slot-scope="{ values, isOpen }">
                      <span
                        class="multiselect__single"
                        v-if="values.length &amp;&amp; !isOpen"
                        >{{ values.length }} dịch vụ đã chọn</span
                      >
                    </template>
                    <span slot="option" slot-scope="scope">
                      <div class="multiselect__checkbox_name">
                        {{ scope.option.text }}
                        <input
                          class="multiselect__checkbox"
                          type="checkbox"
                          v-model="scope.option.checked"
                        />
                      </div>
                    </span>
                    <span slot="noResult" style="font-size: 12px">
                      Không có kết quả
                    </span>
                  </multiselect>
                </b-form>
              </b-col>
              <b-col>
                <b-input
                  placeholder="Mã HĐ"
                  v-model="apiParams.contractCode"
                  size="sm"
                />
              </b-col>
              <b-col>
                <b-input
                  placeholder="Chứng từ"
                  v-model="apiParams.docId"
                  size="sm"
                />
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col>
                <b-form>
                  <b-input
                    placeholder="Tìm chính xác SĐT KH"
                    v-model="apiParams.customerMobile"
                    size="sm"
                  />
                </b-form>
              </b-col>
              <b-col>
                <b-form>
                  <b-form-select
                    v-model="apiParams.isFullPaid"
                    :options="optionPaidStatus"
                    size="sm"
                    class="select-style"
                  ></b-form-select>
                </b-form>
              </b-col>
              <b-col>
                <multiselect
                  v-model="apiParams.status"
                  :options="filteredStatus"
                  :multiple="true"
                  track-by="value"
                  :close-on-select="false"
                  @select="onSelected($event, 'Status', true)"
                  @remove="onSelected($event, 'Status', false)"
                  @search-change="onInputed($event, 'Status')"
                  :searchable="true"
                  :show-labels="false"
                  :showNoResults="true"
                  :showPointer="false"
                  :preselect-first="false"
                  placeholder="Chọn trạng thái"
                  :block-keys="['Tab', 'Enter']"
                  :internal-search="false"
                >
                  <template slot="tag">{{ '' }}</template>
                  <template slot="selection" slot-scope="{ values, isOpen }">
                    <span
                      class="multiselect__single"
                      v-if="values.length &amp;&amp; !isOpen"
                      >{{ values.length }} trạng thái đã chọn</span
                    >
                  </template>
                  <span slot="option" slot-scope="scope">
                    {{ scope.option.text }}
                    <input
                      class="multiselect__checkbox"
                      type="checkbox"
                      v-model="scope.option.checked"
                    />
                  </span>
                  <span slot="noResult" style="font-size: 12px">
                    Không có kết quả
                  </span>
                </multiselect>
              </b-col>
              <b-col>
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  @click="onFilter()"
                  >Lọc</b-button
                >
              </b-col>
              <b-col></b-col>
            </b-row>
            <!-- LIST DATA -->
            <b-row>
              <b-col md="12" lg="12" sm="12">
                <b-table
                  :items="mainList.dataset"
                  :fields="tableFields"
                  :busy="onLoadingList"
                  class="table-bordered table-hover"
                >
                  <template v-slot:table-busy>
                    <vcl-table :speed="5" :animate="true" :columns="10" />
                  </template>
                  <!-- Format id column -->
                  <template v-slot:cell(createdAt)="row">
                    <span style="display: block">{{
                      formatDate(row.item.createdAt)
                    }}</span>
                  </template>
                  <!-- Format type column -->
                  <template v-slot:cell(installMoneyAccountId)="row">
                    <span style="display: block">{{
                      row.item.installmentName
                    }}</span>
                    <span style="display: block">
                      <strong> {{ row.item.storeName }} </strong>
                    </span>
                  </template>
                  <!-- Format accountant column -->
                  <template v-slot:cell(id)="row">
                    <router-link
                      :to="{
                        path: `/orders/edit-order/${row.item.id}`,
                      }"
                      target="_blank"
                      >{{ row.item.id }}
                    </router-link>
                  </template>
                  <template v-slot:cell(totalAmount)="row">
                    {{ convertPrice(row.item.totalAmount) }}
                  </template>
                  <template v-slot:cell(disscountAmount)="row">
                    {{ convertPrice(row.item.disscountAmount) }}
                  </template>
                  <template v-slot:cell(payAmount)="row">
                    {{ convertPrice(row.item.payAmount) }}
                  </template>
                  <template v-slot:cell(paidAmount)="row">
                    {{ convertPrice(row.item.paidAmount) }}
                  </template>
                  <template v-slot:cell(debtAmount)="row">
                    {{ convertPrice(row.item.debtAmount) }}
                  </template>
                  <template v-slot:cell(actions)="row">
                    <div
                      class="d-flex justify-content-center"
                      v-if="row.item.debtAmount > 0"
                    >
                      <b-dropdown size="sm" id="dropdown-left" right no-caret>
                        <b-dropdown-item
                          o-caret
                          @click="editModalPayment(row.item)"
                        >
                          <span style="color: #3f4254; font-size: 12px">
                            <i
                              class="flaticon2-pen"
                              style="font-size: 1rem"
                            ></i>
                            &nbsp; Chỉnh sửa
                          </span>
                        </b-dropdown-item>
                        <template slot="button-content">
                          <i
                            style="font-size: 1rem; padding-right: 0px"
                            class="flaticon2-settings"
                          ></i>
                        </template>
                        <b-dropdown-item>
                          <router-link
                            :to="{
                              name: 'transaction',
                              params: {
                                mainModelProp: {
                                  contactType: '3',
                                  reconciliationDocType: '2',
                                  reconciliationDocId: row.item.id,
                                  type: '1',
                                  contactId: row.item.installMoneyAccountId,
                                  contactName: row.item.installmentName,
                                  amount: row.item.debtAmount,
                                  note: getNoteForPay(row.item),
                                },
                                mode: 'cash',
                                action: 'add',
                                id: 'new',
                              },
                            }"
                          >
                            <span style="color: #3f4254; font-size: 12px">
                              <i
                                style="font-size: 1rem"
                                class="far fa-money-bill-alt"
                              ></i>
                              &nbsp; Thanh toán bằng tiền mặt
                            </span>
                          </router-link>
                        </b-dropdown-item>
                        <b-dropdown-item>
                          <router-link
                            :to="{
                              name: 'transaction',
                              params: {
                                mainModelProp: {
                                  contactType: '3',
                                  reconciliationDocType: '2',
                                  reconciliationDocId: row.item.id,
                                  type: '3',
                                  contactId: row.item.installMoneyAccountId,
                                  contactName: row.item.installmentName,
                                  amount: row.item.debtAmount,
                                  note: getNoteForPay(row.item),
                                },
                                mode: 'bank',
                                action: 'add',
                                id: 'new',
                              },
                            }"
                          >
                            <span style="color: #3f4254; font-size: 12px">
                              <i
                                style="font-size: 1rem"
                                class="fas fa-money-check-alt"
                              ></i>
                              &nbsp; Thanh toán bằng CK ngân hàng
                            </span>
                          </router-link>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </template>
                  <template slot="bottom-row">
                    <td colspan="6" class="text-right font-weight-bolder">
                      <span>Tổng</span>
                    </td>
                    <td class="text-right font-weight-bolder">
                      <span>{{ convertPrice(sumAmount('totalAmount')) }}</span>
                    </td>
                    <td class="text-right font-weight-bolder">
                      <span>{{
                        convertPrice(sumAmount('discountAmount'))
                      }}</span>
                    </td>
                    <td class="text-right font-weight-bolder">
                      <span>{{ convertPrice(sumAmount('payAmount')) }}</span>
                    </td>
                    <td class="text-right font-weight-bolder">
                      <span>{{ convertPrice(sumAmount('paidAmount')) }}</span>
                    </td>
                    <td class="text-right font-weight-bolder">
                      <span>{{ convertPrice(sumAmount('debtAmount')) }}</span>
                    </td>
                    <td v-if="isShow(['TRANSACTION_INSERT'])"></td>
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <!-- PAGINATION -->
            <b-row>
              <b-col lg="3" md="3" sm="12">
                <p class="mt-3 text-dark" style="font-weight: 500">
                  Tổng số:
                  {{ mainList.total }}
                </p>
              </b-col>
              <b-col lg="9" md="9" sm="12">
                <b-pagination-nav
                  class="customPagination"
                  v-show="mainList.pages >= 2"
                  :link-gen="linkGen"
                  :number-of-pages="mainList.pages"
                  use-router
                  @change="fetchMainData(pageInfo)"
                  align="right"
                  first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
                  prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
                  next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
                  last-class="page-item-last btn btn-icon btn-sm my-1 "
                  page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
                >
                  <template v-slot:first-text>
                    <span>
                      <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                    </span>
                  </template>

                  <template v-slot:prev-text>
                    <i class="ki ki-bold-arrow-back icon-xs"></i>
                  </template>

                  <template v-slot:next-text>
                    <i class="ki ki-bold-arrow-next icon-xs"></i>
                  </template>

                  <template v-slot:last-text>
                    <span class="text-info">
                      <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                    </span>
                  </template>
                </b-pagination-nav>
              </b-col>
            </b-row>
          </template>
        </KTCodePreview>
      </b-col>
    </b-row>
    <b-modal ref="excel-modal" hide-footer title="Xuất excel" id="excel-modal">
      <v-form ref="form" lazy-validation>
        <div class="mb-4">
          <div class="row">
            <div class="col-6">
              <b-form-group label="Chọn cột cần xuất:">
                <b-form-radio-group
                  id="radio-group-excel-1"
                  v-model="excel.selectedRowExcel"
                  name="radio-sub-component-1"
                  @change.native="showHeader"
                >
                  <b-form-radio value="all">Tất cả</b-form-radio>
                  <b-form-radio value="row">Chọn cột</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
              <b-form-group label="Danh sách cần xuất:">
                <b-form-radio-group
                  id="radio-group-excel-2"
                  v-model="excel.selectedListExcel"
                  name="radio-sub-component-2"
                >
                  <b-form-radio value="current">Trang hiện tại</b-form-radio>
                  <b-form-radio value="all">Tất cả các trang</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </div>
            <div class="col-6" v-show="excel.showHeaderExcel">
              <b-form-group>
                <template #label>
                  Cột:<br />
                  <b-form-checkbox
                    v-model="excel.allSelected"
                    :indeterminate="excel.indeterminate"
                    aria-describedby="items"
                    aria-controls="items"
                    @change="toggleAll"
                  >
                    {{ excel.allSelected ? 'Bỏ chọn tất cả' : 'Tất cả' }}
                  </b-form-checkbox>
                </template>
                <b-form-checkbox-group
                  id="items"
                  v-model="excel.selectedHeader"
                  :options="excel.optionsHeader"
                  name="items"
                  stacked
                ></b-form-checkbox-group>
              </b-form-group>
            </div>
          </div>
        </div>

        <b-button
          variant="primary"
          size="sm"
          @click="reportClick($event)"
          class="mr-3"
          ref="kt_excel"
        >
          <v-icon small v-show="!btnDisable" class="text-white" v-b-tooltip
            >mdi-download</v-icon
          >
          <strong>Xuất Excel</strong>
        </b-button>

        <b-button
          style="width: 100px"
          variant="secondary"
          size="sm"
          @click="$bvModal.hide('excel-modal')"
        >
          <strong>Hủy</strong>
        </b-button>
      </v-form>
    </b-modal>
    <DebtEditModal
      :item="order"
      :mode="mbPaymentType"
      v-on:refresh="fetchMainData"
    />
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import Loader from '@/view/content/Loader.vue';
import { VclTable } from 'vue-content-loading';
import ApiService from '@/core/services/api.service';
import { cmdUrl } from './../../../utils/apiUrl';
import { getToastInstance } from './../../../utils/toastHelper';
import { mapGetters } from 'vuex';
import moment from 'moment';
import _ from 'lodash';
import { checkPermissions } from '../../../utils/saveDataToLocal';
import datePicker from 'vue-bootstrap-datetimepicker';
import jQuery from 'jquery';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { BASE_URL } from './../../../utils/constants';
import DebtEditModal from '../../components/debt/DebtEditModal.vue';
import { getSubtractDate, getAddDate } from './../../../utils/date';

const thStyleCommon = {
  textAlign: 'center',
  fontWeight: 600,
  color: '#181c32',
  width: '5%',
};
import { checkedOptions, cloneDeep, removeAccents } from '@/utils/common';
import { ORDER_STATUS } from '@/utils/constants';
import timeUtils from '@/utils/date';
import Multiselect from 'vue-multiselect';
import ENUM from '@/utils/enum';
const MIN_RANGE_DATE = getSubtractDate(7);
const MAX_RANGE_DATE = getAddDate();

export default {
  // Data of view.
  data() {
    return {
      title: 'Công nợ trả góp đơn hàng theo ngày',
      listStores: [{ value: null, text: 'Tất cả cửa hàng' }],
      optionInstallment: [],
      optionPaidStatus: [
        { value: null, text: 'Công nợ trả góp' },
        { value: 'true', text: 'Hết nợ' },
        { value: 'false', text: 'Còn nợ' },
      ],
      mainList: {
        dataset: [],
        total: 0,
        pages: 1,
      },
      apiParams: {
        page: 1,
        pageSize: 10,
        storeId: '',
        fromDate: MIN_RANGE_DATE,
        toDate: MAX_RANGE_DATE,
        contactId: [],
        contractCode: null,
        docId: null,
        isFullPaid: null,
        status: [],
      },
      onLoadingList: false,
      tableFields: [
        {
          key: 'createdAt',
          label: 'Ngày',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '50px',
          },
          tdClass: 'text-center',
        },
        {
          key: 'installMoneyAccountId',
          label: 'Dịch vụ trả góp',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '15%',
          },
        },
        {
          key: 'contractCode',
          label: 'Mã hợp đồng',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: 'calc(27% - 120px)',
          },
        },
        {
          key: 'customerName',
          label: 'Khách hàng',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '10%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'customerMobile',
          label: 'Điện thoại',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '8%',
          },
        },
        {
          key: 'id',
          label: 'Chứng từ',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '8%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'totalAmount',
          label: 'Tiền hàng',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '8%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'discountAmount',
          label: 'Chiết khấu',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '8%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'payAmount',
          label: 'Tiền trả góp',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '8%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'paidAmount',
          label: 'Đã thanh toán',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '8%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'debtAmount',
          label: 'Còn nợ',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '8%',
          },
          tdClass: 'text-right',
        },
        {
          key: checkPermissions('TRANSACTION_INSERT') ? 'actions' : '',
          label: '',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '50px',
          },
        },
      ],
      dpConfigs: null,
      filteredOptions: [],
      optionsStore: [
        {
          data: [],
        },
      ],
      searchStock: '',
      inputPropStore: {
        id: 'autosuggest__input',
        placeholder: 'Nhập cửa hàng',
        disabled: false,
      },
      excel: {
        selectedRowExcel: 'all',
        selectedListExcel: 'current',
        showHeaderExcel: false,
        indeterminate: false,
        allSelected: false,
        optionsHeader: [],
        selectedHeader: [],
        optionsHeaderDefault: [],
      },
      btnDisable: false,
      order: {
        orderId: null,
      },
      mbPaymentType: null,
      optionStatus: [],
      initStatus: [],
      statusDefault: [ENUM.ORDER_STATUS.CONFIRMED, ENUM.ORDER_STATUS.SUCCESS],
      filteredStatus: [],
      filteredInstallment: [],
    };
  },
  // Component defination.
  components: {
    KTCodePreview,
    Loader,
    VclTable,
    datePicker,
    DebtEditModal,
    Multiselect,
  },
  // Mounted view.
  mounted() {
    window.addEventListener('keyup', this.onFilterKeyEnter);
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.onFilterKeyEnter);
  },
  created() {
    this.initConstant();
    this.getFillter();

    this.loadBreadcum();
    this.fetchStore();
    this.fetchInstallments();
    this.fetchStoreByUser();
    this.onFilter();
  },
  // Created view.
  methods: {
    ...getToastInstance(),
    initConstant() {
      this.dpConfigs = timeUtils.DP_CONFIG;

      this.initStatus = cloneDeep(ORDER_STATUS).reduce((result, current) => {
        if (this.statusDefault.includes(Number(current.value))) {
          result.push(current);
        }
        return result;
      }, []);

      this.optionStatus = this.getStatus();

      this.optionStatus.map((status) => {
        if (this.statusDefault.includes(Number(status.value))) {
          status.checked = true;
        }
      });
      this.filteredStatus = cloneDeep(this.optionStatus);
      this.apiParams.status = this.initStatus;
    },
    onSelected(option, type, value) {
      switch (type) {
        case 'Status':
          checkedOptions(this.filteredStatus, option, 'value', value);
          break;
        case 'Contact':
          checkedOptions(this.filteredInstallment, option, 'value', value);
          break;
      }
    },
    onInputed(textInput = '', type) {
      switch (type) {
        case 'Status':
          this.searchStatus(textInput);
          break;
        case 'Contact':
          this.searchContact(textInput);
          break;
        default:
          break;
      }
    },
    searchContact(textInput) {
      let options = cloneDeep(this.optionInstallment);
      if (!textInput || !textInput.trim().length) {
        this.filteredInstallment = options.map((o) => {
          const finded = this.filteredInstallment.find(
            (s) => s.value === o.value
          );
          return Object.assign(o, finded);
        });
        return;
      }

      const indexChooseAll = options.findIndex((prop) => prop.value === -1);

      if (indexChooseAll > -1) {
        options.splice(indexChooseAll, 1);
      }

      options = options.map((obj) => {
        const finded = this.filteredInstallment.find(
          (status) => status.value === obj.value
        );
        return Object.assign(obj, finded);
      });
      console.log('textInput', textInput);
      this.filteredInstallment = this.fitlerOptionsBy(
        options,
        textInput,
        'text',
        10
      );
      console.log('filteredInstallment', this.filteredInstallment);
    },
    searchStatus(textInput) {
      let options = cloneDeep(this.optionStatus);
      if (!textInput || !textInput.trim().length) {
        this.filteredStatus = options.map((o) => {
          const finded = this.filteredStatus.find((s) => s.value === o.value);
          return Object.assign(o, finded);
        });
        return;
      }

      const indexChooseAll = options.findIndex((prop) => prop.value === -1);

      if (indexChooseAll > -1) {
        options.splice(indexChooseAll, 1);
      }

      options = options.map((obj) => {
        const finded = this.filteredStatus.find(
          (status) => status.value === obj.value
        );
        return Object.assign(obj, finded);
      });
      this.filteredStatus = this.fitlerOptionsBy(
        options,
        textInput,
        'text',
        10
      );
    },
    fitlerOptionsBy(items, textInput, prop, limit) {
      return cloneDeep(items)
        .filter((item) => {
          if (item) {
            const nameWTUnicode = removeAccents(item[prop] || '');
            const nameInputWTUnicode = removeAccents(textInput);
            const index = nameWTUnicode
              .toLowerCase()
              .indexOf(nameInputWTUnicode.toLowerCase());

            if (index > -1) {
              return true;
            }
          }
          return false;
        })
        .slice(0, limit);
    },
    getStatus() {
      return cloneDeep(ORDER_STATUS.filter((item) => !item.checked));
    },
    onFilterKeyEnter(event) {
      if (event.key === 'Enter') {
        this.onFilter();
      }
    },
    linkGen(pageNum) {
      let param = this.getParamFilters();
      param = _.omitBy(param, _.isNil);
      param.page = pageNum || 1;
      return {
        path: '/accounting/debts/installment-orders',
        query: param,
      };
    },
    onFilter() {
      this.onChangeUrl();
      this.fetchMainData();
    },
    onChangeUrl() {
      this.$route.query.page = 1;

      let param = this.setFillter();
      param = _.omit(param, ['page', 'pageSize']);
      this.$router.push({
        path: `/accounting/debts/installment-orders`,
        query: _.omitBy(param, _.isNil),
      });
    },
    setFillter() {
      const param = this.getParamFilters();
      param.status = param.status.map((s) => s.value);
      param.contactId = param.contactId.map((s) => s.value);
      return param;
    },
    getParamFilters() {
      return {
        page: this.apiParams.page,
        pageSize: this.apiParams.pageSize,
        storeId: this.apiParams.storeId,
        contactId: this.apiParams.contactId || [],
        fromDate: this.apiParams.fromDate
          ? moment(this.apiParams.fromDate, 'DD/MM/YYYY').format('DD/MM/YYYY')
          : null,
        toDate: this.apiParams.toDate
          ? moment(this.apiParams.toDate, 'DD/MM/YYYY').format('DD/MM/YYYY')
          : null,
        contractCode: this.apiParams.contractCode,
        docId: this.apiParams.docId,
        customerMobile: this.apiParams.customerMobile,
        isFullPaid: this.apiParams.isFullPaid || null,
        status: this.apiParams.status || [],
      };
    },
    getFillter() {
      const query = this.$route.query || null;

      if (!_.isEmpty(query)) {
        const storeId = query.searchStock || null;
        const fromDate = query.fromDate || null;
        const toDate = query.toDate || null;

        const contactFromUrl = query.contactId
          ? query.contactId.map((s) => Number(s))
          : [];
        const contactDistinct = contactFromUrl;

        this.filteredInstallment.map((option) => {
          if (contactFromUrl.includes(Number(option.value))) {
            option.checked = true;
          }
        });

        const contact = cloneDeep(this.optionInstallment).reduce(
          (result, current) => {
            if (contactDistinct.includes(Number(current.value))) {
              current.checked = true;
              result.push(current);
            }
            return result;
          },
          []
        );

        const contractCode = query.contractCode || null;
        const docId = query.docId || null;

        const customerMobile = query.customerMobile || null;
        const isFullPaid = query.isFullPaid || null;

        const statusFromUrl = query.status
          ? query.status.map((s) => Number(s))
          : [];
        const statusDistinct = this.statusDefault.concat(statusFromUrl);

        this.filteredStatus.map((option) => {
          if (statusFromUrl.includes(Number(option.value))) {
            option.checked = true;
          }
        });

        const status = cloneDeep(ORDER_STATUS).reduce((result, current) => {
          if (statusDistinct.includes(Number(current.value))) {
            current.checked = true;
            result.push(current);
          }
          return result;
        }, []);

        const page = query.page || 1;
        const pageSize = query.pageSize || 10;

        this.apiParams = Object.assign(
          {},
          {
            page,
            pageSize,
            storeId,
            fromDate,
            toDate,
            contractCode,
            docId,
            customerMobile,
            isFullPaid,
            status,
            contactId: contact,
          }
        );
      }
    },
    editModalPayment: function (item) {
      this.mbPaymentType = 'installment-orders';
      this.order.orderId = item.id;
      this.$bvModal.show('md-debt');
    },
    payBank: function (item) {
      this.$router.push({
        path: '/accounting/transactions/bank/add/new',
        params: {
          mainModelProp: {
            contactType: '3',
            reconciliationDocType: '2',
            reconciliationDocId: item.id,
            type: '3',
            contactId: item.installMoneyAccountId,
            contactName: item.installmentName,
          },
        },
      });
    },
    fetchMainData: async function () {
      let page = this.$route.query.page || 1;
      if (!page) {
        page = 1;
      }
      this.apiParams.page = page;
      this.apiParams.type = this.$route.params.mode;
      if (this.searchStock.trim() === '') {
        this.apiParams.storeId = null;
      }
      const params = cloneDeep(this.apiParams);
      params.status = params.status.map((s) => s.value);

      params.contactId = params.contactId.map((s) => s.value);
      // Init request header.
      this.onLoadingList = true;
      ApiService.query(cmdUrl.DebtUrl.installmentOrders, {
        params: params,
      })
        .then((response) => {
          const respData = response.data.data;
          this.mainList = respData;
          this.onLoadingList = false;
        })
        .catch((err) => {
          const message = err.response.data.message;
          this.makeToastFailure(message);
          this.onLoadingList = false;
        });
    },
    formatDate: function (dateVal) {
      const date = moment(dateVal);
      return date.format('DD/MM/yyyy');
    },
    loadBreadcum: function () {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: 'Kế toán', route: 'accountant' },
        { title: 'Công nợ' },
        { title: this.title },
      ]);
    },
    convertPrice: function (number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    fetchStore: async function () {
      ApiService.get('/stores/getStores').then((response) => {
        let data = {
          id: '',
          name: 'Tất cả cửa hàng',
          shortName: 'Tat ca cua hang',
        };
        this.optionsStore[0].data.push(data);
        const stores = response.data.data;
        stores.map((element) => {
          this.optionsStore[0].data.push(element);
        });
        this.filteredOptions = [{ data: this.optionsStore[0].data }];
      });
    },
    fetchInstallments: async function () {
      let params = {
        page: 1,
        pageSize: 1000,
      };
      ApiService.query('/installments', { params }).then((response) => {
        const listData = response.data.data.result;
        _.map(listData, (item) => {
          this.optionInstallment.push({
            value: item.id,
            text: item.name,
          });
          this.filteredInstallment = cloneDeep(this.optionInstallment);
        });
      });
    },
    sumAmount(key) {
      const outItems = this.mainList.dataset;
      return _.reduce(
        outItems,
        (sum, item) => {
          return sum + parseFloat(item[key]);
        },
        0
      );
    },
    getNoteForPay: function (item) {
      let note = `Nhận thanh toán từ dịch vụ trả góp\n`;
      note += `Mã HĐ: ${item.id}\n`;
      note += `Dịch vụ: ${item.installmentName}\n`;
      note += `Mã hợp đồng: ${item.contractCode}`;
      return note;
    },
    isShow(conditions) {
      return checkPermissions(conditions);
    },
    onSelectedStock(option) {
      this.searchStock = option.item.name;
      this.apiParams.storeId = option.item.id;
    },
    onInputChangeStore(text) {
      if (!text) {
        text = '';
      }
      this.searchStock = text;

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.optionsStore[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, 10);

      this.filteredOptions = [
        {
          data: filteredData,
        },
      ];
    },
    fetchStoreByUser() {
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          const stores = data.data.stores;
          if (stores.length === 1) {
            this.searchStock = stores[0].name;
            this.apiParams.storeId = stores[0].id;
            this.inputPropStore.disabled = true;
          } else {
            this.optionsStore[0].data = [];
            const data = {
              id: '',
              name: 'Tất cả cửa hàng',
              shortName: 'Tat ca cua hang',
            };
            this.optionsStore[0].data.push(data);
            stores.map((element) => {
              this.optionsStore[0].data.push(element);
            });
            this.filteredOptions = [{ data: this.optionsStore[0].data }];
            this.searchStock = data.name;
            this.apiParams.storeId = data.id;
          }
        }
        this.fetchMainData();
      });
    },
    excelModal: async function () {
      this.excel.optionsHeader = [
        {
          text: 'Ngày tạo',
          value: 'date',
          disabled: false,
          selected: true,
        },
        {
          text: 'Cửa hàng',
          value: 'storeName',
          disabled: false,
          selected: true,
        },
        {
          text: 'Dịch vụ trả góp',
          value: 'installMoneyAccountName',
          disabled: false,
          selected: true,
        },
        {
          text: 'Mã hợp đồng',
          value: 'contractCode',
          disabled: false,
          selected: true,
        },
        {
          text: 'Tên khách hàng',
          value: 'customerName',
          disabled: false,
          selected: true,
        },
        {
          text: 'SĐT khách hàng',
          value: 'customerMobile',
          disabled: false,
          selected: true,
        },
        {
          text: 'Chứng từ',
          value: 'id',
          disabled: false,
          selected: true,
        },
        {
          text: 'Tiền hàng',
          value: 'totalAmount',
          disabled: false,
          selected: true,
        },
        {
          text: 'Chiết khấu',
          value: 'discountAmount',
          disabled: false,
          selected: true,
        },
        {
          text: 'Tiền trả góp',
          value: 'payAmount',
          disabled: false,
          selected: true,
        },
        {
          text: 'Đã thanh toán',
          value: 'paidAmount',
          disabled: false,
          selected: true,
        },
        {
          text: 'Còn nợ',
          value: 'debtAmount',
          disabled: false,
          selected: true,
        },
        {
          text: 'TK thu phí quẹt thẻ',
          value: 'creditFeeAcountName',
          disabled: false,
          selected: true,
        },
        {
          text: 'Hình thức thu phí quẹt thẻ',
          value: 'payCreditFeeTypeName',
          disabled: false,
          selected: true,
        },
        {
          text: 'Số tiền TK thu phí quẹt thẻ',
          value: 'otherFees',
          disabled: false,
          selected: true,
        },
        {
          text: 'Ghi chú bán hàng',
          value: 'saleNote',
          disabled: false,
          selected: true,
        },
        {
          text: 'Ghi chú nội bộ',
          value: 'customerNote',
          disabled: false,
          selected: true,
        },
      ];
      this.excel.optionsHeaderDefault = this.excel.optionsHeader.filter(
        (x) => x.default
      );
      this.excel.selectedHeader = this.excel.optionsHeader
        .filter((x) => x.selected)
        .map((x) => x.value);

      this.$refs['excel-modal'].show();
    },
    reportClick: async function () {
      const submitButton = this.$refs['kt_excel'];
      submitButton.classList.add(
        'spinner',
        'spinner-light',
        'spinner-left',
        'spinner-sm'
      );
      this.btnDisable = true;
      let key = uuidv4();

      let customsChids = this.excel.optionsHeader.filter((x) => x.customs);

      if (customsChids.length > 0) {
        customsChids.forEach((element) => {
          let index = this.excel.selectedHeader.findIndex(
            (x) => x === element.value
          );
          if (index > -1) {
            let childs = element.customs.map((x) => x.value);
            this.excel.selectedHeader.splice(index, 1);
            this.excel.selectedHeader =
              this.excel.selectedHeader.concat(childs);
          }
        });
      }

      let data = {
        // items: this.mainList.dataset,
        headers: this.excel.selectedHeader,
        params: {},
        paramSearchs: {},
      };

      if (this.excel.selectedRowExcel !== 'row') {
        let selectAll = this.excel.optionsHeader.map((x) => x.value);

        if (customsChids.length > 0) {
          customsChids.forEach((element) => {
            let index = selectAll.findIndex((x) => x === element.value);
            if (index > -1) {
              let childs = element.customs.map((x) => x.value);
              selectAll.splice(index, 1);
              selectAll = selectAll.concat(childs);
            }
          });
        }
        data.headers = selectAll;
      }

      let totalPage =
        this.excel.selectedListExcel === 'current'
          ? parseInt(this.$route.query.page || 1)
          : Math.ceil(this.mainList.total / 150);

      let i =
        this.excel.selectedListExcel === 'current'
          ? parseInt(this.$route.query.page || 1)
          : 1;

      for (i; i <= totalPage; i++) {
        let params = Object.assign({}, this.apiParams);

        params.page = i;
        if (this.excel.selectedListExcel !== 'current') params.pageSize = 150;

        let path = this.$route.path;
        params.mode = path.substr(path.lastIndexOf('/') + 1);

        if (this.searchStock.trim() === '') {
          params.storeId = null;
        }

        if (params.contactId) {
          params.contactId = params.contactId.map((c) => c.value);
        }

        if (params.status) {
          params.status = params.status.map((c) => c.value);
        }

        data.params = {
          status: this.excel.selectedListExcel,
          totalPage: totalPage,
          currentPage: i,
          key: key,
        };

        data.paramSearchs = params;
        let result = await this.exportExcel(data);

        if (!result) return;

        if (data.params.totalPage === data.params.currentPage) {
          data.params.status = 'download';
          await this.exportExcel(data);
        }
      }
      this.btnDisable = false;
      submitButton.classList.remove(
        'spinner',
        'spinner-light',
        'spinner-right'
      );
      this.$bvModal.hide('excel-modal');
    },
    exportExcel(data) {
      let baseUrl = BASE_URL;
      let url = `${baseUrl}debts/export-excel`;
      let options = { url: url, method: 'POST', data: data };

      if (data.params.status === 'download') {
        options.responseType = 'blob';
      }

      return axios(options)
        .then((response) => {
          if (data.params.status === 'download') {
            let headerLine = response.headers['content-disposition'];
            let startFileNameIndex = headerLine.indexOf('"') + 1;
            let endFileNameIndex = headerLine.lastIndexOf('"');
            let filename = headerLine.substring(
              startFileNameIndex,
              endFileNameIndex
            );
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', filename);
            document.body.appendChild(fileLink);

            fileLink.click();
          } else {
            return response;
          }
        })
        .catch(() => {
          this.makeToastFailure('Xuất Excel không thành công');
        });
    },
    showHeader() {
      if (this.excel.selectedRowExcel === 'row') {
        this.excel.showHeaderExcel = true;
      } else {
        this.excel.showHeaderExcel = false;
      }
    },
    toggleAll(checked) {
      this.excel.selectedHeader = checked
        ? this.excel.optionsHeader.map((x) => x.value)
        : this.excel.optionsHeaderDefault.map((x) => x.value);
    },
  },

  computed: {
    ...mapGetters(['breadcrumbs', 'pageTitle', 'layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },
  },
  beforeRouteUpdate: function (to, from, next) {
    this.mode = to.params.mode;
    next();
  },
  watch: {
    'excel.selectedHeader': {
      handler: function (newVal) {
        if (newVal.length === 0) {
          this.excel.indeterminate = false;
          this.excel.allSelected = false;
        } else if (newVal.length === this.excel.optionsHeader.length) {
          this.excel.indeterminate = false;
          this.excel.allSelected = true;
        } else {
          this.excel.indeterminate = true;
          this.excel.allSelected = false;
        }
      },
      deep: true,
    },
    mode: function (val) {
      this.mode = val;
      this.fetchMainData();
      this.loadBreadcum();
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.bootstrap-datetimepicker-widget.dropdown-menu {
  width: 21em;
}

.customPagination /deep/ .page-item-first .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item-prev .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-next .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-last .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item .page-link {
  border-radius: 0.42rem;
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  -webkit-box-align: center;
  height: 2.25rem;
  min-width: 2.25rem;
  padding: 0.5rem;
  color: #7e8299;
}

.customPagination /deep/ .page-item {
  color: #ecf1f6;
}

.customPagination /deep/ .page-item .router-link-exact-active {
  color: #fff;
}

.customPagination /deep/ .page-item .page-link:hover {
  background-color: #3699ff;
  color: #fff;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  opacity: 1;
}

.icon:hover {
  background-color: #90c6fc;
}

.sttClass {
  width: 20px;
}
.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.375rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e5eaee;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}
.select-style {
  border-radius: 0.28rem;
  box-shadow: none;
  border: 1px solid #ced4da;
}
input {
  border: 1px solid #ced4da;
}
.date-style {
  display: flex;
  align-items: center;
}
</style>

<style lang="scss">
.installment-order {
  .multiselect__checkbox_name {
    display: block;
    overflow: hidden;
  }

  .multiselect__checkbox {
    vertical-align: middle;
    float: right;
  }
  .multiselect__tags {
    height: calc(1.35em + 1.1rem + 2px);
    display: block;
    border-radius: 0.28rem;
    border: 1px solid #ced4da;
    font-size: 0.925rem;
    min-height: initial;
    padding-top: 0.3rem;
  }

  .multiselect__option {
    padding: 5px;
    padding-top: 8px;
    min-height: 30px;
    white-space: pre-line;
  }

  .multiselect__select {
    width: 3rem;
    height: 2.5rem;
    top: 0.1rem;
  }

  .multiselect__placeholder {
    color: #495057;
    padding-left: 0.2rem;
  }

  .multiselect__element {
    font-size: 0.925rem;
  }

  .multiselect__input {
    padding-top: 0.2rem;
    font-size: 0.925rem;
  }

  .multiselect__single {
    font-size: 0.925rem;
    line-height: 1.9;
    padding-left: 0.2rem;
    display: inline-block;
    max-width: 100%;
  }
  .pre-line {
    white-space: pre-line;
  }
}
</style>
